$spacing-person: 0.25rem;

.list-person {
  &__container {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
  }

  &__person {
    overflow: hidden;
    display: flex;
    align-items: top;
    justify-content: center;
    flex-direction: column;
    background: $primary-background-color;
    border: $tine-default-border;
    flex-basis: 100%;
    margin: 0 $column-gutter/2 $column-gutter/2;
  
    @include tablet() {
      flex: 1 0 25%;
      max-width: calc(25% - #{$column-gutter});
  
      &:nth-child(4n+0) {
        margin-right: 0;
      }
    }
  }

  h2 {
    margin: 0;
    padding: 2rem 0 1.5rem;
    text-align: center;
  }

  h3 {
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.25;
    margin-bottom: 0.15rem;
  }

  p {
    margin: 0;
    font-size: 0.8rem;
    padding-bottom: $spacing-person;
  }

  &__text {
    padding: $column-gutter / 2;
  }

  &__role {
    font-size: 0.7rem !important;
    padding-bottom: 1rem !important;
  }

  &__download {
    display: block;
  }

  &__expand {
    cursor: pointer;
    font: inherit;
    background: none;
    border: none;
    display: inline;
    margin: 0.75rem 0 1rem 0;
    padding: 0;
    outline: none;
    outline-offset: 0;
    font-size: 0.8rem;
    text-align: left;
    color: $link-color;
    text-decoration: $link-text-decoration;
  
    &:hover {
      color: $link-color-hover;
      text-decoration: $link-text-decoration-hover;
    }

    &::after {
      content: "";
      position: relative;
      left: 8px;
      top: -2px;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transition: all 100ms ease-in-out;
      transform: rotate(-45deg);
    }

    &--open {
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__more {
    display: none;

    &--open {
      display: block;
    }
  }

  .content-media {
    display: contents;
  }
}
