@import "parts/list-person";


// BEM example
.block {
	background: $primary-color;

	&__element {
		font-size: 1.2rem;
		color: $secondary-color;

		&--modifier {
			color: $tertiary-color;
		}
	}
}
